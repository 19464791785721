import blastBashSvg from "./assets/blast-bash.svg";
import gamingSvg from "./assets/gaming.svg";
import dotaSvg from "./assets/dota.svg";
import csSvg from "./assets/cs.svg";
import deadlockSvg from "./assets/deadlock.svg";
import { DetailedHTMLProps } from "react";

type Props = DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const csIcon = (props: Props) => <img src={csSvg} alt="CS2" {...props} />;
const dotaIcon = (props: Props) => <img src={dotaSvg} alt="Dota" {...props} />;
const blastBashIcon = (props: Props) => <img src={blastBashSvg} alt="BLAST Bash" {...props} />;
const gamingIcon = (props: Props) => <img src={gamingSvg} alt="Gaming" {...props} />;
const deadlockIcon = (props: Props) => <img src={deadlockSvg} alt="Deadlock" {...props} />;

/**
 * Returns icon img element for the given game id
 */
export const GameIcon = ({
  gameId,
  ...props
}: Props & { gameId: "cs" | "dota" | "supercell" | "gaming" | "deadlock" }) => {
  if (gameId === "cs") return csIcon(props);
  if (gameId === "dota") return dotaIcon(props);
  if (gameId === "supercell") return blastBashIcon(props);
  if (gameId === "gaming") return gamingIcon(props);
  if (gameId === "deadlock") return deadlockIcon(props);

  return null;
};
