import { Icon } from "@shared/components/Icon";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const DotaSearchButton = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <Link
      className={classNames(
        "hidden items-center gap-4 whitespace-nowrap rounded-full border border-yellow bg-canvas-95 px-4 py-2 lg:flex",
        className,
      )}
      onClick={onClick}
      to="/dota/users"
    >
      <span className="block text-neutral-10 font-style-b3-body-copy">Search user by steam ID</span>
      <Icon icon="magnifyingGlass" />
    </Link>
  );
};
