import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { z } from "zod";
import { useAuth } from "../../../auth/AuthProvider/hooks/useAuth";
import { useAlert } from "../../../providers/AlertProvider/hooks/useAlert";
import { useDDSEvents } from "../useDDSEvents";

const pointsReceivedMessageSchema = z.object({
  amount: z.number(),
  purpose: z.string(),
  context: z.string(),
  transactionDate: z.string(),
});

function getNotificationDetails(purpose: string) {
  const basePattern = /^([A-Za-z0-9]+)_([\d]+)$/;
  const match = purpose.match(basePattern);

  if (match) {
    const basePurpose = match[1];
    const streakNumber = parseInt(match[2], 10);

    const humanReadableWord = basePurpose
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/([A-Za-z])(\d+)/g, "$1 $2")
      .replace(/(\d+)([A-Za-z])/g, "$1 $2")
      .split(/[_\s]/)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ");

    let message;
    switch (basePurpose) {
      case "DailyVisit":
        message =
          streakNumber === 1
            ? "visiting BLAST.tv. Come back tomorrow to start a streak!"
            : `visiting BLAST.tv ${streakNumber} days in a row!`;
        break;
      case "CounterStrikleStarted":
        message = streakNumber === 1 ? "playing Counter-Strikle." : `playing Counter-Strikle for ${streakNumber} days.`;
        break;
      case "CounterStrikleWon":
        message = streakNumber === 1 ? "winning Counter-Strikle." : `winning Counter-Strikle for ${streakNumber} days.`;
        break;
      case "CSQuizStarted":
        message = streakNumber === 1 ? "playing CS Quiz." : `playing CS Quiz for ${streakNumber} days.`;
        break;
      case "CSQuizDefused":
        message =
          streakNumber === 1
            ? "defusing the bomb in CS Quiz."
            : `defusing the bomb in CS Quiz for ${streakNumber} days.`;
        break;
      case "CSGuessrStarted":
        message = streakNumber === 1 ? "playing CS Guessr." : `playing CS Guessr for ${streakNumber} days.`;
        break;
      case "CSGuessrPerfectAnswer":
        message =
          streakNumber === 1
            ? "getting the maximum score in a CS Guessr answer!"
            : `getting the maximum score in CS Guessr for ${streakNumber} times.`;
        break;
      case "PickemsSubmitted":
        message = "submitting your Pick'ems.";
        break;
      case "LivePollVote":
        message = "participating in a Live Poll!";
        break;
      case "FantasySubmitted":
        message = "submitting your Fantasy team.";
        break;
      case "HigherLowerStarted":
        message =
          streakNumber === 1 ? "playing Higher Lower." : `playing Higher Lower for ${streakNumber} consecutive days.`;
        break;
      case "HigherLower10InARow":
        message =
          streakNumber === 1
            ? "getting 10 correct answers in a row in Higher Lower."
            : `getting 10 correct answers in a row in Higher Lower for ${streakNumber} consecutive days.`;
        break;
      case "HigherLower20InARow":
        message =
          streakNumber === 1
            ? "getting 20 correct answers in a row in Higher Lower."
            : `getting 20 correct answers in a row in Higher Lower for ${streakNumber} consecutive days.`;
        break;
      case "HigherLower30InARow":
        message =
          streakNumber === 1
            ? "getting 30 correct answers in a row in Higher Lower."
            : `getting 30 correct answers in a row in Higher Lower for ${streakNumber} consecutive days.`;
        break;
      case "TeamTakeoverVictory":
        message = "instigating a team takeover and being victorious!";
        break;

      default:
        message = "purpose";
    }

    return { title: `${humanReadableWord}${streakNumber > 1 ? ` Streak ${streakNumber}` : ""}`, message };
  } else {
    return { title: purpose, message: purpose };
  }
}

export function usePointsEvents() {
  const queryClient = useQueryClient();
  const { userId, isAuthenticated } = useAuth();

  const alert = useAlert();

  const pointsReceivedListener = useCallback(
    (message: unknown) => {
      const parsedMessage = pointsReceivedMessageSchema.parse(message);

      // Do not show notification if it's triggered by live drop event
      if (!parsedMessage.purpose?.startsWith("PointsClaimed")) {
        const { title, message } = getNotificationDetails(parsedMessage.purpose);

        alert.addPointsNotification(parsedMessage.amount, title, message);
      }
      if (userId)
        queryClient.invalidateQueries({
          queryKey: ["userWallet", userId],
        });
    },
    [alert, queryClient, userId],
  );

  useDDSEvents({
    pointsReceivedListener,
    disabled: !isAuthenticated,
  });
}
