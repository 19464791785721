import { z } from "zod";

import { CircuitSchema } from "./circuit";

export const TournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  circuitId: z.string().nullish(),
  circuit: CircuitSchema.extend({
    gameId: z.string(),
  }).nullish(),
  location: z.string().nullish(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.coerce.number().nullish(),
  externalId: z.string().nullish(),
  metadata: z
    .object({
      sponsors: z
        .object({
          header: z.string().nullish(),
          items: z
            .object({
              logoKey: z.string(),
              url: z.string(),
            })
            .array(),
        })
        .nullish()
        .catch(null),
      ticketing: z
        .object({
          items: z
            .object({
              title: z.string(),
              startDate: z.string(),
              endDate: z.string(),
              cta: z.object({
                url: z.string(),
              }),
            })
            .array(),
        })
        .nullish()
        .catch(null),
      headerCta: z.object({ text: z.string(), url: z.string() }).nullish().catch(null),
    })
    .nullish(),
});

export type Tournament = z.infer<typeof TournamentSchema>;
